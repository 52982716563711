import React from "react"
import { Grid } from "@mui/material"
import { GridContainer, Container } from "../grid"



const Page = (props) => {
  return (
  <Container pt={{xs:0}}>
    <GridContainer {...props} sx = { (theme) => ({
      alignItems: 'stretch'
    })}>
      <Grid item lg={4} xxl={4}/>
      <Grid component={'article'}
        item
        lg={8}
        // xxl={7}
      >
        {props.children}
      </Grid>
    </GridContainer>
  </Container>
  )
}

export default Page