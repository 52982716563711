import React from "react"
import Seo from "./../../components/seo"
import { Box } from "@mui/system"
import Page from "../../components/page"

const DialogiSubscription = ({data}) => {
  console.log(data)

  return (
    <>
      <Seo title="Postani naročnik" />
      <Box sx = { (theme) => ({
        bgcolor: theme.palette.white
      })}>
        <Page className={'content'}>
          <h1>Postani naročnik</h1>
          <p>Dialogi izidejo sedemkrat v letu, letna naročnina za posameznike (fizične osebe) znaša <strong>28,00 evrov, tj. 4,00 evre na številko,</strong> za ustanove (pravne osebe) pa <strong>35,00 evrov, tj. 5,00 evrov na številko.</strong> Naročila sprejemamo z zaokroženim letom. </p>

          <p>Naročnik postanete tako, da pošljete  sporočilo na elektronski naslov <a href="mailto:info@aristej.si">info@aristej.si</a> in mu dodate svoj točen poštni naslov. Naročilo bomo v skladu z vašo željo upoštevali s tekočim ali prihodnjim letom, zmeraj za najmanj eno leto in do preklica.</p>

          <p>Morebitne odpovedi naročnin upoštevamo z naslednjim zaokroženim letom.</p>

        </Page>
      </Box>
    </>
  )
}

export default DialogiSubscription
